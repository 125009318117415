<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->
    <!-- Start Column Area  -->
    <div class="rn-column-area rn-section-gap mt--150">
      <v-container>
        <v-form ref="form"
        v-model="valid"
        lazy-validation>
        <center><h3 class="mb--100"><b>Αλλαγή κωδικού πρόσβασης</b></h3></center>

          <br>
          <v-text-field
            v-model="current_password"
            :rules="[rules.required]"
            label="Τωρινός κωδικός *"
            type="password"
            validate-on-blur
            required
            outlined
            shaped
          ></v-text-field>
          <v-text-field
            label="Κωδικός *"
            v-model="password"
            type="password"
            :rules="[rules.required, rules.length]"
            persistent-hint
            validate-on-blur
            required
            outlined
            shaped
          ></v-text-field>
          <v-text-field
            v-model="password_confirmation"
            :rules="[rules.required, rules.length, passwordConfirmationRule]"
            outlined
            type="password"
            shaped
            validate-on-blur
            label="Επανάληψη κωδικού"
            required
          ></v-text-field>
          <p class="error" v-if="errors">{{ errors }}</p>

          <v-btn
            color="primary"
            dark
            class="mr-4"
            @click="saveNewPassword"
          >
            Αποθήκευση νέου κωδικού
          </v-btn>
        </v-form>
          
      </v-container>
    </div>
    <!-- End Column Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "@/components/header/Header";
  import Footer from "@/components/footer/FooterTwo";
  import axios from 'axios';
  import { mapMutations, mapActions, mapGetters } from "vuex";


  export default {
    components: {
      Header,
      Footer,
    },
    data: () => ({
      valid: true,
      password: '',
      errors: '',
      password_confirmation: '',
      current_password: '',
      rules: {
        required: value => !!value || 'Το πεδίο είναι υποχρεωτικό.',
        length: v => (!v || v.length >= 6) || 'Ο κωδικός πρέπει να περιέχει πάνω απο 6 χαρακτήρες.',
      },
    }),
    async  created () {
      await this.getUser();
      if(!this.user){
         this.$router.push('/')
         return
      }
    },
    computed: {
        passwordConfirmationRule() {
          return () => (this.password === this.password_confirmation) || 'Οι κωδικοί δεν ταιριάζουν'
        }
    },
    methods: {
      async saveNewPassword () {
        try{
          if(this.$refs.form.validate()){
            this.$refs.form.resetValidation();            
              const response = await axios.post('external/me/change-password', 
              {
                  current_password: this.current_password,
                  password: this.password,
                  password_confirmation: this.password_confirmation,
              }).then(response => {
                   this.$swal({
                      title: "Συγχαρητήρια",
                      text: "Ο κωδικός πρόσβασης άλλαξε επιτυχώς.",
                      icon: "success", //built in icons: success, warning, error, info
                      timer: 3000, //timeOut for auto-close
                        buttons: {
                          confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                          },
                          cancel: {
                            text: "Cancel",
                            value: false,
                            visible: true,
                            className: "",
                            closeModal: true,
                          }
                        }
                    });
                    this.password = '';
                    this.current_password = '';
                    this.password_confirmation = '';
                    this.errors = '';
              }).catch(error => {
                  if (error.response.data.code == 400) {
                      this.errors = "Παρακαλώ ελέγξτε τα στοιχεία σας.";
                  } else {
                      this.errors = "Παρακαλώ ελέγξτε τα στοιχεία σας.";
                  }
                  this.password = '';
                  this.current_password = '';
                  this.password_confirmation = '';
              });
            
          }else{
            return false;
          }            
        }catch(e){
            this.error = e.message;
            return e;
        }
      },
      
    },
  }
</script>
